
let preloadDataHashmap = {}

export const setPreloadRoute = (route) => {
    preloadDataHashmap[route] = true
}

export const isPreloaded = (route) => {
    return preloadDataHashmap[route] === true
}

export const CheckValidity = (checkItem) => {
    return (checkItem !== undefined && checkItem !== null)
}

export const CheckEmpty = (checkItem) => {
    return (checkItem.length !== 0)
}

export const onLoadImages = (ref) => {
    let status = false

    const updateStatus = (images) => {
        status = images.map((image) => image.complete).every((item) => item === true)
    };

    if (!ref.current) {

    } else {
        const imagesLoaded = Array.from(ref.current.querySelectorAll("img"));
        if (imagesLoaded.length === 0) {
            status = true;
        } else {
            imagesLoaded.forEach((image) => {
                image.addEventListener("load", () => updateStatus(imagesLoaded), {
                    once: true
                });
                image.addEventListener("error", () => updateStatus(imagesLoaded), {
                    once: true
                });
            });
        }
    }
    return status;
};