export const IconColorList = [
    {
        image: 'check',
        colorList: ['#83BF4F', '#3E4347'],
    },
    {
        image: "logo",
        colorList: ["#555555"]
    },
    {
        image: 'facebook',
        colorList: ["#1877F2", "white"]
    },
    {image: 'twitter', colorList: ["#2DAAE1", "white"]},
    {image: 'reddit', colorList: ["#FF5700", "white", "white"]},
    {image: 'quora', colorList: ["#B92B27"]},
    {image: 'pinterest', colorList: ["#CC2127"]},
    {image: 'medium', colorList: ["white", "black"]},
    {image: 'instagram', colorList: ["#F3825A", "white"]},
    {image: 'linked_in', colorList: ["#379AD0", "#FEFEFE", "#FEFEFE", "#FEFEFE"]},
    {image: 'youtube', colorList: ["white", "#E8E0E0", "#CD201F"]},
    {image: 'close', colorList: ["#09092d",]},
    {image: 'blinx', colorList: ["var(--light-sky)",]},
    {image: 'check_circle', colorList: ["#2fa87e",]},
    {image: 'financial', colorList: ["#09092d",]},
    {image: 'health_care', colorList: ["#09092d",]},
    {image: 'logoText', colorList: ["#555555","#555555","#555555","#555555","#555555","#555555",]},
    {image: 'arrow', colorList: ["#00E9C1"]},
    {image: 'drop_down', colorList: ["white"]},
    {
        image: 'Patent_Essentiality_Determination',
        colorList:[
            "#00E9C1",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
            "white",
        ]
    },
    {
        image: "Disruptive_Technology_Scouting",
        colorList: ["", "#05AF92", "#00E9C1", "#00E9C1", "#00E9C1"],

    },
    {
        image: "Portfolio_Management_and_Optimization",
        colorList: ["", "#364560", "#364560", "white", "white", "white", "#00E9C1"],

    },
    {
        image:"Licensing_SEPs_Identification",
        colorList:["#00E9C1", "#00E9C1","#00E9C1","#00E9C1"],
    },
    {
        image:"R_D_and_Innovation_Monitoring",
        colorList:["#00E9C1","#25CAAE","#00E9C1"],
    },
    {
        image:"Benchmarking",
        colorList:["#00E9C1","#00E9C1","#00E9C1","#00E9C1"]

    },
    {
        image : 'Risk_Assessment',
        colorList : ["", "#00E9C1","#00E9C1" ,"#F39090",]
    },
    {
        image : 'SDG_Goals_Tracking',
        colorList : [" ", "#00E9C1","#00E9C1","#00E9C1","#00E9C1","#00E9C1", "white","white"]
    },
    {
        image : 'Competitive_Intelligence_and_Benchmarking',
        colorList:["","#00E9C1","#00E9C1", "#00E9C1", "#00E9C1"]

    },

];