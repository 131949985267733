import React from "react";
import './index.css';
import './indexResponsiveStyleSheet.css';
import loadable from '@loadable/component'
import {createBrowserHistory} from 'history';
import {Route, Switch} from 'react-router-dom';
import Popup from "reactjs-popup";
import Icon from "./Assets/IconLibrary/Icon";
import {lazyWithPreload} from "react-lazy-with-preload";
import {isPreloaded} from "./Components/CommonMethods";
import AlarmLoading from "./Assets/Loading/AlarmLoading";

const MainBackground = loadable(() => import("./Components/Backgrounds/MainBackground"));
const SolutionPreload = lazyWithPreload(() => import("./Pages/Solution"));
const LandingImagePreload = lazyWithPreload(() => import("./Assets/Images/Illustrations/landing_image.svg"));
const PricingPreload = lazyWithPreload(() => import("./Pages/PricingPlan"));
const CompanyPreload = lazyWithPreload(() => import("./Pages/Company"));
const TermsAndConditionPreload = lazyWithPreload(() => import("./Pages/TermsAndConditions/TermsAndCondition"));
const PrivacyPolicyPreload = lazyWithPreload(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const HomePreload = lazyWithPreload(() => import("./Pages/Home"))
const ErrorPagesPreload = lazyWithPreload(() => import("./Components/ErrorPages/ErrorPages"))
const HeaderPreload = lazyWithPreload(() => import('./Components/Header/Header'));


const Footer = loadable(() => import('./Components/Footer/Footer'))
const Header = loadable(() => import('./Components/Header/Header'))
const Home = loadable(() => import('./Pages/Home'))
const Solution = loadable(() => import('./Pages/Solution'))
const PricingPlan = loadable(() => import('./Pages/PricingPlan'))
const Company = loadable(() => import('./Pages/Company'))
const PrivacyPolicy = loadable(() => import('./Pages/PrivacyPolicy/PrivacyPolicy'))
const TermsAndCondition = loadable(() => import('./Pages/TermsAndConditions/TermsAndCondition'))
const ErrorPages = loadable(() => import('./Components/ErrorPages/ErrorPages'))

export const history = createBrowserHistory();

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.containerRef = React.createRef()

        this.state = {
            homePageLoaded: false,
            headerPagePreload: false,
            route: "",
            productVideoPopup: false,
            scrollTop: 0,
            showNeural: false,
        }
    }

    componentDidMount() {


        HeaderPreload.preload().then(() => {
            this.setState({
                headerPagePreload: true
            })
        })
        HomePreload.preload().then(() => {
            this.setState({
                homePageLoaded: true
            })
        })

        LandingImagePreload.preload().then(() => {
        })
        ErrorPagesPreload.preload().then(() => {
        })
        SolutionPreload.preload().then(() => {
        })
        PricingPreload.preload().then(() => {
        })
        CompanyPreload.preload().then(() => {
        })
        TermsAndConditionPreload.preload().then(() => {
        })
        PrivacyPolicyPreload.preload().then(() => {
        })

    }

    openProductVideo = () => {
        this.setState({
            productVideoPopup: true
        })
    }

    closeProductVideo = () => {
        this.setState({
            productVideoPopup: false
        })
    }

    closeContactUsForm = () => {
        this.setState({
            contactUsPopup: false,
        })
    }

    manualScrollHandler = () => {
        this.containerRef.current.scrollIntoView({
            // behavior: "smooth",
            block: "start"

        })
    }
    submitForm = () => {
        this.setState({
            contactUsPopup: false,
            // thankyouPopup: true,
        })
    }

    onScroll = () => {
        const scrollTop = this.containerRef.current.scrollTop
        if (scrollTop === 0) {
            this.setState({scrollTop: scrollTop, reloadAppListPage: false})
        } else {
            this.setState({scrollTop: scrollTop, reloadAppListPage: false})
        }
    }


    changeRoute = (route) => {
        let preloaded = isPreloaded(route)
        if (preloaded === true) {
            this.props.history.push("/" + route)
        } else {
            this.preloadComponents(route)
            this.props.history.push("/" + route)
        }
        this.manualScrollHandler(0)
    };


    onVisibilityChange = (isVisible) => {
        if (isVisible) {
            this.setState({showNeural: true});
        } else {
            this.setState({showNeural: false});
        }
    };

    preloadComponents = () => {

    }

    render() {
        let {scrollTop} = this.state;
        return (
            <div className="main-layout" ref={this.containerRef} onScroll={this.onScroll}>
                {
                    this.state.homePageLoaded !== true || this.state.headerPagePreload !== true?
                    // this.state.homePageLoaded ?
                        <div className="main-body" style={{height:"90vh"}}>
                            <AlarmLoading loadingText={""}/>
                        </div>
                        :
                        <>
                           <MainBackground/>
                            <Header scrollTop={scrollTop}
                                    onHover={(route) => this.preloadComponents(route)}
                                    routingPage={(route) => this.changeRoute(route)}/>
                            <div className="main-body">
                                <Switch>
                                    <Route
                                        path="/solutions"
                                        component={Solution}/>
                                    <Route
                                        path="/pricing"
                                        component={props => <PricingPlan {...props}/>}/>
                                    <Route
                                        path="/company"
                                        component={props => <Company {...props}/>}/>

                                    <Route
                                        path="/terms-and-conditions"
                                        component={props => <TermsAndCondition {...props}/>}/>
                                    <Route
                                        path="/privacy-policy"
                                        component={props => <PrivacyPolicy {...props}/>}/>
                                    <Route
                                        exact
                                        path="/"
                                        component={props => <Home
                                            openProductVideo={this.openProductVideo.bind(this)} {...props}/>}/>
                                    <Route
                                        exact
                                        path="/home"
                                        component={props => <Home
                                            openProductVideo={this.openProductVideo.bind(this)} {...props}/>}/>
                                    <Route
                                        path="/*"
                                        component={props => <ErrorPages {...props}/>}/>
                                    <Route component={ErrorPages} exact/>

                                </Switch>
                            </div>
                            <Footer routingPage={(route) => this.changeRoute(route)}
                                    onHover={(route) => this.preloadComponents(route)}/>
                        </>
                }


                <Popup contentStyle={{
                    background: "transparent",
                    padding: 0,
                    border: "none",
                    borderRadius: "none",
                }}
                       open={this.state.productVideoPopup}
                       closeOnDocumentClick={false}
                       lockScroll={true}>
                    <div className="product-video-layout">
                        <button className="product-video-close-btn" onClick={() => {
                            this.setState({productVideoPopup: false})
                        }}>
                            <Icon name="close" colorList={["white"]}/>
                        </button>

                        <div className="product-loading">
                        </div>

                        <div className="product-video-container">
                            <iframe
                                className="product-video" src="https://www.youtube.com/embed/HAj_d6dR_xM"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay=false; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}
