import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import {BrowserRouter, BrowserRouter as Router, Route} from 'react-router-dom';
import App from "./App";

ReactDOM.render(
    <BrowserRouter>
        <Router>
            <Route path="/" component={App} />
        </Router>
    </BrowserRouter>,
    document.getElementById('root'));
