import React from 'react';
import Icons from './IconLibrary';
import PropTypes from 'prop-types';
import {IconColorList} from "./IconColorList";

const DefaultStyles = {display: 'inline-block', verticalAlign: 'middle', boxShadow: '1px 1px 4px #cdcdcd'};

const checkUndefined = (element) => {
    return (element !== undefined && element !== null && element.length !== 0)
}

const Icon = ({size, stroke, transform, styles, name, viewBox,fillRule, className, colorList, def, defID}) => {
    const style = {...DefaultStyles, styles};
    const ColorList = (
        colorList !== undefined ?
            Icons[name] !== undefined ?
                Icons[name].map((_, index) =>
                    colorList.map((colorL, index) =>
                        colorL
                    ))
                :
                null
            :
            IconColorList.filter(DuoColor => {
                    if (DuoColor.image === name) {
                        return DuoColor
                    } else
                        return null
                }
            ).map((ColorList, index) =>
                ColorList.colorList.map((colorL, index) =>
                    colorL
                )
            )

    )

    return (

        <svg
            className={className}
            style={styles}
            viewBox={checkUndefined(viewBox) ? viewBox : "0 0 24 24"}
            width={`${checkUndefined(size) ? size : 24}px`}
            height={`${checkUndefined(size) ? size : 24}px`}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"

        >

            {
                def &&
                <defs>
                    {def}
                </defs>
            }

            {
                Icons[name] !== undefined ?
                    Icons[name].map((_, index) => (
                        def?
                            <path d={Icons[name][index]}
                                  fill={"url(#"+defID+")"}
                                  stroke={"url(#"+defID+")"} transform={transform}/>
                                  :
                            ColorList[0] !== undefined ?
                                <path d={Icons[name][index]}
                                      fill={ColorList[0][index]}
                                      fillRule={fillRule}
                                      stroke={stroke} transform={transform}
                                /> :
                                <path d={Icons[name][index]}
                                      fill={ColorList[index]}
                                      fillRule={fillRule}
                                      stroke={stroke} transform={transform}
                                />
                        )
                    )
                    :
                    null
            }
        </svg>
    )
}
Icon.defaultProps = {
    size: 24,
    stroke: '',
    styles: {},
    viewBox: "0 0 24 24",
    className: "",
    fillRule: "evenodd"
}
Icon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.object,
    name: PropTypes.string.isRequired,
    viewBox: PropTypes.string,
    style: PropTypes.shape(PropTypes.object),
    className: PropTypes.string,
    transform: PropTypes.string,
    colorList: PropTypes.array,
    fillRule: PropTypes.string,
}
export default Icon;